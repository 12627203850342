<template>
	<div class="pui-form">
		<v-form class="" ref="form">
			<v-row class="mt-4 pui-form-layout">
				<pui-field-set :title="$t('gisweb.detail.shipdata.title')" style="width: 100%">
					<v-row dense>
						<v-col cols="1">
							<pui-text-field
								:id="`vesselname-giswebdetail`"
								v-model="stop.shipdata.name"
								:label="$t('gisweb.detail.shipdata.vesselname')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="1">
							<pui-text-field
								:id="`vesseltype-giswebdetail`"
								v-model="stop.vesseltypedesc"
								:label="$t('gisweb.detail.shipdata.vesseltype')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="1">
							<pui-text-field
								:id="`vesselimo-giswebtoolbar`"
								v-model="stop.shipdata.imo"
								:label="$t('gisweb.detail.shipdata.vesselimo')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="1">
							<pui-text-field
								:id="`callSign-giswebtoolbar`"
								v-model="stop.shipdata.callSign"
								:label="$t('gisweb.detail.shipdata.callsign')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="1">
							<pui-text-field
								:id="`vesselflag-giswebdetail`"
								v-model="stop.vesselflag"
								:label="$t('gisweb.detail.shipdata.vesselflag')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="1">
							<pui-text-field
								:id="`vesselmanager-giswebdetail`"
								v-model="stop.consigneename"
								:label="$t('gisweb.detail.shipdata.vesselmanager')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="1">
							<pui-text-field
								:id="`vesselobservations-giswebdetail`"
								v-model="stop.shipdata.comments"
								:label="$t('gisweb.detail.shipdata.vesselobservations')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="1">
							<pui-text-field
								:id="`vesseldraught-giswebdetail`"
								v-model="stop.shipdata.draught"
								:label="$t('gisweb.detail.shipdata.vesseldraught')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="1">
							<pui-text-field
								:id="`vesseldraughtreal-giswebdetail`"
								v-model="stop.shipdata.draught"
								:label="$t('gisweb.detail.shipdata.vesseldraughtreal')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="1">
							<pui-text-field
								:id="`vesselgt-giswebdetail`"
								v-model="stop.shipdata.gt"
								:label="$t('gisweb.detail.shipdata.vesselgt')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="1">
							<pui-text-field
								:id="`vesselbeam-giswebdetail`"
								v-model="stop.shipdata.beam"
								:label="$t('gisweb.detail.shipdata.vesselbeam')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="1">
							<pui-text-field
								:id="`vessellength-giswebdetail`"
								v-model="stop.shipdata.length"
								:label="$t('gisweb.detail.shipdata.vessellength')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
				</pui-field-set>
				<pui-field-set :title="$t('gisweb.detail.stopdata.title')" style="width: 100%">
					<v-row dense>
						<v-col cols="1">
							<pui-text-field
								:id="`status-giswebdetail`"
								v-model="stop.status"
								:label="$t('gisweb.detail.stopdata.status')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="3">
							<pui-text-field
								:id="`location-giswebdetail`"
								v-model="stop.location"
								:label="$t('gisweb.detail.stopdata.location')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>

						<v-col cols="2">
							<pui-text-field
								:id="`berthingtypename-giswebdetail`"
								v-model="stop.berthingtypename"
								:label="$t('gisweb.detail.stopdata.berthingtypename')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="3">
							<pui-select
								:id="`fromportcode-giswebdetail`"
								:attach="`fromportcode-giswebdetail`"
								:label="$t('gisweb.detail.stopdata.fromportname')"
								disabled
								toplabel
								clearable
								required
								v-model="stop.fromportcode"
								modelName="worldport"
								:modelFormMapping="{ code: 'fromportcode' }"
								:itemsToSelect="[{ code: stop.fromportcode }]"
								:itemValue="['code']"
								:itemText="(item) => `${item.code} - ${item.name} (${item.countrycode})`"
								reactive
							></pui-select>
						</v-col>
						<v-col cols="3">
							<pui-select
								:id="`toportcode-giswebdetail`"
								:attach="`toportcode-giswebdetail`"
								:label="$t('gisweb.detail.stopdata.toportname')"
								disabled
								toplabel
								clearable
								required
								v-model="stop.toportcode"
								modelName="worldport"
								:modelFormMapping="{ code: 'toportcode' }"
								:itemsToSelect="[{ code: stop.toportcode }]"
								:itemValue="['code']"
								:itemText="(item) => `${item.code} - ${item.name} (${item.countrycode})`"
								reactive
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="2">
							<pui-text-field
								:id="`bollardini-giswebdetail`"
								v-model="stop.bollardini"
								:label="$t('gisweb.detail.stopdata.bollardini')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="2">
							<pui-text-field
								:id="`bollardend-giswebdetail`"
								v-model="stop.bollardend"
								:label="$t('gisweb.detail.stopdata.bollardend')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="4">
							<pui-date-field
								:id="`eta-giswebdetail`"
								v-model="stop.eta"
								:label="$t('gisweb.detail.stopdata.eta')"
								toplabel
								time
								disabled
							></pui-date-field>
						</v-col>
						<v-col cols="4">
							<pui-date-field
								:id="`etd-giswebdetail`"
								v-model="stop.etd"
								:label="$t('gisweb.detail.stopdata.etd')"
								toplabel
								time
								disabled
							></pui-date-field>
						</v-col>
					</v-row>
				</pui-field-set>
				<v-col cols="12">
					<pui-field-set :title="$t('gisweb.detail.operationsdata.title')" style="display: contents">
						<pui-master-detail
							componentName="giswebdetailgrid"
							parentModelName="stop"
							:parentPk="getPk()"
							:parentPkChildFk="{ id: 'stop_id' }"
							:formDisabled="true"
							:modalDialog="true"
							:parentModel="stop"
							:showCreateBtn="false"
							:showDeleteBtn="false"
							:navigableDetail="false"
						></pui-master-detail>
					</pui-field-set>
				</v-col>
			</v-row>
		</v-form>
	</div>
</template>

<script>
import GisWebDetailGrid from './GiswebDetailGrid.vue';

export default {
	components: {
		GisWebDetailGrid
	},
	mixins: [],
	data() {
		return {
			selectedItems: {}
		};
	},
	props: {},
	created() {
		const stopData = localStorage.getItem('stopGisweb');
		if (stopData) {
			this.stop = JSON.parse(stopData);
		}
	},
	methods: {
		getPk() {
			return btoa(`{"id": ${this.stop.id}}`);
		}
	},
	computed: {}
};
</script>

<style scoped></style>
